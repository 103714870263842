<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectType">
                <el-button type="primary" plain style="width:144px;">
                    {{curTypeLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in cheetLogLev" :key="item.id" :command="item" icon="el-icon-help">
                        {{item.name}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-date-picker type="daterange" style="margin-left: 8px;" :editable="false" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" v-model="queryDate" @change="onSelectDate">
            </el-date-picker>
            <el-input v-model="queryPcid" placeholder="过滤玩家ID"  @change="onSelectCid" style="margin-left: 8px;width: 120px;"></el-input>
        </el-col>
        <el-col :span="24">
            <el-table :data="cheetloglist" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:8+'px'}" class="serverlist">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                      <el-form-item v-for="item in labelArr" :key="item.prop" :label="item.label" style="width: 100%;" label-width="90px">
                        <span>{{ props.row[item.prop] }}</span>
                      </el-form-item>
                    </el-form>
                  </template>
                </el-table-column>
                <el-table-column v-for="item in labelArr" :key="item.prop" :prop="item.prop" :label="item.label" show-overflow-tooltip></el-table-column>
                <el-table-column label="级别" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{feedbackTypeName(scope.row.level)}}</span>
                </template>
                </el-table-column>            
            </el-table>
            <el-pagination
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next"
              background
              :page-size="20"
              :total="total"
              :current-page="curPage">
            </el-pagination>
        </el-col>
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import tool from '../../tool.js'

    export default {
        data() {
            return {
                curTypeId: '0',
                curTypeLabel: '全部级别',
                cheetLogLev: [{id:'0',name:'全部级别'},{id:'1',name:'级别1'},{id:'2',name:'级别2'},{id:'3',name:'级别3'},{id:'4',name:'级别4'}],
                labelArr: [
                    {prop:'id',label:'id'},
                    {prop:'createtime',label:'时间'},
                    {prop:'pcid',label:'玩家ID'},
                    {prop:'key',label:'键名'},
                    {prop:'value',label:'上报数据'},
                    {prop:'level',label:'风险等级'},
                ],
                cheetloglist: [],
                curPage: 1,
                total: 0,
                curGroup: '',
                curServerid: '',
                serverGroups: [],
                serverItems: [],
                queryDate: [], // 查询日期范围
                queryPcid: '', // 查询玩家pcid
                tableHeight: document.documentElement.clientHeight-136-32, 
            }
        },
        created: function() {
            this.refreshGroupList();
            this.refreshcheetloglist();
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
        },
        methods: {
            // 选择反馈类型
            onSelectType(item) {
                this.curTypeLabel = item.name
                this.curTypeId = item.id
                this.curPage = 1;
                // 刷新选择结果
                this.refreshcheetloglist();
            },     
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 选择分组
            onSelectGroup(item) {
                this.curServerid = 0;
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: item.id,
                }).then((response) => {
                    this.serverItems = response.data.list;
                    this.serverItems.splice(0, 0, {
                        id: 0,
                        title: "全部区服"
                    });
                });
                this.refreshcheetloglist();
            },
            // 选择区服
            onSelectServer() {
                this.refreshcheetloglist();
            },
            // 选择日期
            onSelectDate() {
                if (this.queryDate) {
                    this.refreshcheetloglist();
                    this.curPage = 1;
                }
            },
            // 选择pcid
            onSelectCid() {
                console.log("onSelectCid...", this.queryPcid);
                this.curPage = 1;
                this.refreshcheetloglist();
            }, 
            // 查询列表
            refreshcheetloglist() {
                let rqs = {
                    level:this.curTypeId,
                    group: this.curGroup?this.curGroup.id:-1,
                    server: this.curServerid?this.curServerid:0,
                    page:this.curPage,
                };
                if (this.queryDate && this.queryDate.length) {
                    rqs.dfrom = tool.dateFormatD(this.queryDate[0]);
                    rqs.dto = tool.dateFormatD(this.queryDate[1]);
                }
                if (this.queryPcid) {
                    rqs.pcid = this.queryPcid.trim();
                }
                this.gameHttp(3055, rqs).then((response) => {
                    this.dialogForm = false;
                    if (0 == response.data.errcode) {
                        this.total = response.data.total;
                        this.cheetloglist = response.data.list;
                    }
                })
            },
            // 分页查看
            handleCurrentChange(page) {
                this.curPage = page;
                this.refreshcheetloglist();
            },
            // 反馈类型的名称
            feedbackTypeName(id) {
                for (let i=0; i<this.cheetLogLev.length; i++) {
                    if (id == this.cheetLogLev[i].id) {
                        return this.cheetLogLev[i].name;
                    }
                }
                return 'notype';
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
    
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>
